<template>
<div class="layout-topbar">
    <a v-if="appSideBar" class="menu-button" @click="$emit('menubutton-click')">
        <i class="pi pi-bars"></i>
    </a>
    
    <router-link to="/" class="logo" :style="appSideBar == false ? {'margin-left': '10px'} : ''">
        <img alt="logo" src="./assets/images/adt-logo.png" />
    </router-link>
    <ul ref="topbarMenu" class="topbar-menu">
        <li>
            <div class="user-info" @click="toggle">
                <Avatar :image="getPhoto()" class="profile-avatar" size="large" shape="circle" />
                <div class="profile-info">
                    <div style="height: 20px; text-align: left;">
                        <span class="profile-name">
                            {{emp_name}}
                        </span>
                    </div>
                    <div style="height: 20px; text-align: left; padding-left: 12px">
                        <small>{{ user_role }}</small>
                    </div>
                </div>
            </div>
            <Menu ref="menu" :model="items" :popup="true" />
        </li>
    </ul>

    <Dialog v-model:visible="showDialog" @hide="onDialogClosed()" :style="showUserProfileForm == true ? {width: '55rem'} : {width: '38rem'}" :modal="true">
        <template #header>
            <h5 v-if="!showMsg.isMsgShow && showUserProfileForm" style="margin: 0px">Edit Profile</h5>
            <h5 v-if="!showMsg.isMsgShow && showChangePasswordForm" style="margin: 0px">Change Password</h5>
            <InlineMessage v-if="showMsg.isMsgShow" style="padding: 4px 10px" :severity="showMsg.severity">{{showMsg.content}}</InlineMessage>
        </template>

        <EmployeeForm v-if="showUserProfileForm" :UpdateProfile="true" :EmpID="empid.id" />
        <ChangePassword v-if="showChangePasswordForm" />
    </Dialog>
</div>
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Menu from 'primevue/menu';
import Avatar from 'primevue/avatar';

import { checkSessionLocalStorage } from './middleware/checkSession';
import EmployeeService from './service/EmployeeService';
import ChangePassword from './components/ChangePassword.vue';
import EmployeeForm from './components/EmployeeForm.vue';
import AuthService from './service/AuthService';

export default {
    setup(){
        const router = useRouter()
        const store = useStore()
        const empid = checkSessionLocalStorage()
        let userphoto = ref(null)
        let emp_name = ref(null)
        let user_role = ref(null)
        const userIs = inject('userIs')
        
        let showDialog = ref(false)
        let showUserProfileForm = ref(false)
        let showChangePasswordForm = ref(false)
        const showMsg = computed(() => store.state.showMsg)
        const appSideBar = computed(() => store.state.appSideBar)

        const menu = ref();
        const items = ref([
            {
                label: 'Profile',
                icon: 'pi pi-user-edit',
                command: () => openDialog('UserProfile'),
            },
            {
                label: 'Change Password',
                icon: 'pi pi-unlock',
                command: () => openDialog('ChangePassword'),
            },
            {
                separator: true,
            },
            {
                label: 'Logout',
                icon: 'pi pi-power-off',
                // url: '/logout'
                command: () => logoutUser(),
            },
        ])
        
        const employeeService = ref(new EmployeeService())
        const authService = ref(new AuthService())
        
        onMounted(()  =>  {            
            employeeService.value.topBarEmployeeInfo(empid.id).then(data => {            
                if(!data.errorResponse) {
                    emp_name.value = data.first_name
                    userphoto.value = data.photo == '' ? null : data.photo
                    user_role.value = data.user_role
                    userIs.type = user_role.value
                }
            })
        })
        
        function getPhoto() {
            if(userphoto.value == null) {
                return require('../public/images/image-placeholder.jpg')
            } else {
                return require('../public/images/'+userphoto.value)
                // return require(`../public/images/${userphoto.value}`)
            }
        }

        //Methods
        const toggle = (event) => {
            menu.value.toggle(event);
        }

        function logoutUser() {
            if(localStorage.getItem('isAuthenticate') != null) {
                localStorage.removeItem('isAuthenticate')

                authService.value.logoutUser().then(res => {
                    if(res.message == 'success'){
                        router.push({ path: '/' })
                    }
                })

                // router.push({path: '/'})
            }
        }
        
        function openDialog(form) {
            showDialog.value = true
            if(form == 'UserProfile') {
                showUserProfileForm.value = true
            }

            if(form == 'ChangePassword') {
                showChangePasswordForm.value = true
            }
        }

        function onDialogClosed() {
            showUserProfileForm.value = false
            showChangePasswordForm.value = false
        }

        return {
            appSideBar,
            menu,
            items,
            toggle,
            getPhoto,
            empid,
            emp_name,
            user_role,

            showDialog,
            onDialogClosed,
            showUserProfileForm,
            showChangePasswordForm,
            showMsg
        }
    },
    components: {
        Menu,
        Avatar,
        ChangePassword,
        EmployeeForm,
    },
};
</script>

<style lang="scss" scoped>
.user-info {
    cursor: pointer;
}
</style>
