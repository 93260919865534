import axios from '../axios.instance'

export default class EmployeeService {
    async getEmployees(){
        try {
            const res = await axios.get('/employee')
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async getEmployeeById(isUpdateProfile, id){
        try {
            if(isUpdateProfile) {
                const res = await axios.get('/employee/getprofile/'+id);
                return res.data;
            } else {
                const res = await axios.get('/employee/'+id);
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addEmployee(data){
        try {
            if(data != ''){
                const res = await axios.post('/employee', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateEmployee(isUpdateProfile, emp_id, data) {
        try {
            if(isUpdateProfile) {
                if(data != '') {
                    const res = await axios.put('/employee/updateprofile/'+emp_id, {data})
                    return res.data;
                }
            } else {
                if(data != '') {
                    const res = await axios.put('/employee/'+emp_id, {data})
                    return res.data;
                }
            }
        } catch (error) {
            // const err = error.response
            return error.response.data
        }
    }

    async deleteEmployee(emp_id, photoName){
        try {
            if(emp_id != 0) {
                const res = await axios.delete('/employee/'+emp_id, { params: {photoname: photoName} })
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async topBarEmployeeInfo(id) {
        try {
            const res = await axios.get('/employee/topbarinfo/'+id);
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }
}