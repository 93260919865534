<template>
    <div class="layout-wrapper">
        <div class="progress-bar">
            <ProgressBar v-if="showProgressBar" mode="indeterminate" style="height: 0.22rem"  />
        </div>
        
        <Login v-if="$route.name == 'login' && !networkError" />

        <LoadingScreen v-if="showLoadingScreen" />
        
        <div v-if="checkSessionLocalStorage() != null && !networkError" class="layout-content" :style="showAppSideBar == false ? {'margin-left':'0'} : {}">
            <AppTopBar v-if="showAppTopBar" @menubutton-click="onMenuButtonClick" />
            <div v-if="showAppSideBar">
                <AppMenu :active="isSidebarActive" @onMenuItemClick="closeSideBar()" />
                <div :class="['layout-mask', { 'layout-mask-active': isSidebarActive }]" @click="closeSideBar()"></div>
            </div>
            
            <router-view />

            <!-- <router-view v-slot="{ Component }">
                <transition name="show">
                    <component :is="Component"></component>
                </transition>
            </router-view> -->
        </div>
        <Dialog class="session-exp-dialog" header="Session expired" v-model:visible="displaySessionExpire" :style="{width: '400px'}" :closable="false" :closeOnEscape="false" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem; color: var(--orange-500);" />
                <span>Your session has expired, please login again.</span>
            </div>
            <template #footer>
                <Button label="OK" @click="closeConfirmation" class="p-button" />
            </template>
        </Dialog>

        <Toast style="z-index: 1020" position="top-right" />
    </div>
</template>

<script>
import { ref, computed, provide, reactive, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from "primevue/usetoast";
import DomHandler from './components/utils/DomHandler';
import AppTopBar from './AppTopBar.vue';
import AppMenu from './AppMenu.vue';
import Login from './views/Login.vue'
import LoadingScreen from './views/LoadingScreen.vue'
import { checkSessionLocalStorage } from './middleware/checkSession';

export default {
    name: 'App',
    setup(){
        const store = useStore()
        const router = useRouter()
        const toast = useToast()
        const isSidebarActive = ref(false)
        const showLoadingScreen = computed(() => store.state.loadingScreen)
        const networkError = computed(() => store.state.networkError)
        const showAppSideBar = computed(() => store.state.appSideBar)
        const showAppTopBar = computed(() => store.state.appTopBar)
        const showProgressBar = computed(() => store.state.progressBar)

        const displaySessionExpire = computed(() => store.state.sessionExpire)
        const requestTimeout = computed(() => store.state.requestTimeout)

        const userIs = reactive({ type: '' })
        provide('userIs', userIs)

        watchEffect(() => {
            if(requestTimeout.value) {
                toast.add({severity:'error', summary: 'Error ', detail: 'Service request timeout.', life: 5000});
            }
        })


        //Methods
        const onMenuButtonClick = () => {
            if (isSidebarActive.value) {
                isSidebarActive.value = false;
                DomHandler.removeClass(document.body, 'blocked-scroll');
            } else {
                isSidebarActive.value = true;
                DomHandler.addClass(document.body, 'blocked-scroll');
            }
        }

        const closeSideBar = () => {
            isSidebarActive.value = false;
            DomHandler.removeClass(document.body, 'blocked-scroll');
        }

        const closeConfirmation = () => {
            if(localStorage.getItem('isAuthenticate') != null) {
                localStorage.removeItem('isAuthenticate')
            }
            store.dispatch('sessionExpire', false)
            router.push({path: '/'})
        };

        return {
            showAppSideBar,
            showAppTopBar,
            onMenuButtonClick,
            closeSideBar,
            isSidebarActive,
            displaySessionExpire,
            closeConfirmation,
            checkSessionLocalStorage,
            showLoadingScreen,
            networkError,
            showProgressBar,
        }
    },

    watch: {
        $route(to) {
            document.title = to.meta.title || 'Your Website';
        },
    },

    components: {
        AppMenu,
        AppTopBar,
        Login,
        LoadingScreen,
    },
};
</script>

<style>
#app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #2c3e50;
    margin-top: 60px;
}
.session-exp-dialog .p-dialog-title {
    padding-top: .3rem;
}
.session-exp-dialog .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.progress-bar {
    position: fixed;
    top: 0; 
    left: 0; 
    right: 0; 
    z-index: 2102;
}
</style>
