import axios from '../axios.instance'

export default class UserAccountService {
    async getUserAccount(){
        try {
            const res = await axios.get('/useraccount')
            return res.data
        } catch (error) {
            return error.response.data
        }
    }

    async addUserAccount(data){
        try {
            if(data != ''){
                const res = await axios.post('/useraccount', { data })
                return res.data
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateUserAccount(user_id, data) {
        try {
            if(data != '') {
                const res = await axios.put('/useraccount/'+user_id, {data})
                return res.data;
            }
        } catch (error) {
            // const err = error.response
            return error.response.data
        }
    }

    async deleteUserAccount(user_id) {
        try {
            if(user_id != 0) {
                const res = await axios.delete('/useraccount/'+user_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async changePassword(data) {
        try {
            if(data != '') {
                const res = await axios.put('/useraccount/changepwd', {data})
                return res.data;
            }
        } catch (error) {
            // const err = error.response
            return error.response.data
        }
    }

    //USE TO GET ALL EMPLOYEE NAME TO SET IN USER PROFILE LIST
    async getEmployeeName() {
        try {
            const res = await axios.get('/useraccount/getempname')
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }
}