// export default (store) => {
//     const ability = store.getters.ability

//     ability.update(store.state.rules)
//     console.log(ability)
//     return store.subscribe((mutation) => {
//         if (mutation.type === 'DEFINE_ABILITY') {
//           ability.update(mutation.payload)
//           console.log(ability)
//         }
//     })
// }

import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import { checkSessionLocalStorage } from '../middleware/checkSession';
import axios from '../axios.instance';

const ability = new Ability();

//RE-DEFINE RULE IF USER REFRESH PAGE
const loggedInUser = checkSessionLocalStorage();
if(loggedInUser) {
    axios.get('/modulepermission/getrules').then(result => {
      if(!result.data.errorResponse) {
        defineAbility(unpackRules(result.data.rules));
      }
    }).catch(error => {
        console.log(error.response.data);
    })
}

function defineAbility(rules) {
  ability.update(rules);
}


export { ability, defineAbility };
