export const uploadImage = {
    state: { //Contain data we want to use in our store, like data property of Vue Component
        hasPhoto: false, //false
        photoInfo: {
            photoName: '',
            fileExt: ''
        },
    }, 
    getters: { //Get data from our state

    }, 
    mutations: { //Function to change value of state (commit mutation), we can't trigger asyc code inside mutation.
        hasPhoto(state, photostate){
            state.hasPhoto = photostate;
        },
        setPhotoName(state, photoname){
            state.photoInfo.photoName = photoname
        },
        setPhotoExt(state, photoext){
            state.photoInfo.fileExt = photoext
        }
    }, 
    actions: { //Function to commit mutations, we can have async code in actions
        hasPhoto({ commit }, photostate){
            commit('hasPhoto', photostate)
        },
        setPhotoName({ commit }, photoname) {
            commit('setPhotoName', photoname)
        },
        setPhotoExt({ commit }, photoext) {
            commit('setPhotoExt', photoext)
        }
    },
};