export const transportationInfo = {
    state: { //Contain data we want to use in our store, like data property of Vue Component
        transportationUpdateForm: {}, //Use to store transportation data in update form
    }, 
    getters: { //Get data from our state

    }, 
    mutations: { //Function to change value of state (commit mutation), we can't trigger asyc code inside mutation.
        TRANSPORTATION_UPDATEFORM(state, payload) {
            state.transportationUpdateForm = payload
        },
    }, 
    actions: { //Function to commit mutations, we can have async code in actions
        transportation_updateform({ commit }, value) {
            commit('TRANSPORTATION_UPDATEFORM', value)
        },
    },
};