import { createApp } from 'vue'
import { store } from './store'
import App from './App.vue'
import router from './router'
import { abilitiesPlugin } from '@casl/vue';
import { ability } from './service/ability';

import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import DataTable from 'primevue/datatable';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import InlineMessage from 'primevue/inlinemessage';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import Row from 'primevue/row';
import ScrollPanel from 'primevue/scrollpanel';
import Tag from 'primevue/tag';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import './assets/styles/styles.css'
import './assets/styles/scss/app.scss'

const app = createApp(App)
app.use(abilitiesPlugin, ability)
app.use(router)
app.use(store)
app.use(ConfirmationService)
app.use(ToastService)
app.use(PrimeVue)

//PrimeVue Component
app.component('AutoComplete', AutoComplete)
app.component('Button', Button)
app.component('Calendar', Calendar)
app.component('Checkbox', Checkbox)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('Editor', Editor)
app.component('DataTable', DataTable)
app.component('Divider', Divider)
app.component('Dropdown', Dropdown)
app.component('InlineMessage', InlineMessage)
app.component('InputText', InputText)
app.component('InputMask', InputMask)
app.component('InputNumber', InputNumber)
app.component('Message', Message)
app.component('Panel', Panel)
app.component('ProgressBar', ProgressBar)
app.component('RadioButton', RadioButton)
app.component('Rating', Rating)
app.component('Row', Row)
app.component('ScrollPanel', ScrollPanel)
app.component('Tag', Tag)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Textarea', Textarea)
app.component('Toast', Toast)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Dialog', Dialog),

app.mount('#app')