<template>
    <div :class="['layout-sidebar', { active: active }]">
        <div class="layout-menu">
            <div v-if="can('read', 'booking') || can('read', 'complaint') || can('read', 'itinerary')" class="menu-category">
                <i class="pi pi-calendar-minus icon-spacing"></i>Booking
            </div>
            <div class="menu-items" @click="$emit('onMenuItemClick')">
                <router-link v-if="can('read', 'booking')" to="/booking">Booking</router-link>
                <router-link v-if="can('read', 'complaint')" to="/complaint">Complaint</router-link>
                <router-link v-if="can('read', 'itinerary')" to="/itinerary">Itinerary</router-link>
            </div>

            <div v-if="can('read', 'hotel') || can('read', 'restaurant') || can('read', 'transportation') || can('read', 'tour guide') || can('read', 'driver') || can('read', 'boat')" class="menu-category">
                <i class="pi pi-bookmark icon-spacing"></i>Suppliers
            </div>
            <div class="menu-items" @click="$emit('onMenuItemClick')">
                <router-link v-if="can('read', 'hotel')" to="/hotel">Hotel</router-link>
                <router-link v-if="can('read', 'restaurant')" to="/restaurant">Restaurant</router-link>
                <router-link v-if="can('read', 'transportation')" to="/transportation">Transportation</router-link>
                <router-link v-if="can('read', 'tour guide')" to="/tourguide">Tour Guide</router-link>
                <router-link v-if="can('read', 'driver')" to="/driver">Driver</router-link>
                <router-link v-if="can('read', 'boat')" to="/boat">Boat</router-link>
            </div>

            <div v-if="can('read', 'employee')" class="menu-category">
                <i class="pi pi-id-card icon-spacing"></i>Company
            </div>
            <div class="menu-items" @click="$emit('onMenuItemClick')">
                <router-link v-if="can('read', 'employee')" to="/employee">Employee</router-link>
                <!-- <router-link v-if="can('read', 'Inven')" to="/inventory">Inventory</router-link> -->
            </div>
            <div v-if="can('read', 'user account' || 'role management')" class="menu-category">
                <i class="pi pi-desktop icon-spacing"></i>System
            </div>
            <div class="menu-items" @click="$emit('onMenuItemClick')">
                <router-link v-if="can('read', 'user account')" to="/useraccount">User Account</router-link>
                <router-link v-if="userIs.type == 'Administrator'" to="/rolemanagement">Role Management</router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
.icon-spacing {
    padding-right: 8px;
}
</style>

<script>
import { inject } from 'vue';
import { useAbility } from '@casl/vue';

export default {
    props: {
        active: Boolean
    },
    setup(){
        const { can } = useAbility()
        const userIs = inject('userIs')
        let activeSubmenus = {}

        //Methods
        const toggleSubmenu = (event, name) => {
            activeSubmenus[name] = activeSubmenus[name]
                ? false
                : true;
            activeSubmenus = { ...activeSubmenus };
            event.preventDefault();
        }
        const isSubmenuActive = (name, routerIsActive) => {
            if (activeSubmenus.prototype.hasOwnProperty.call(name)) {
                return activeSubmenus[name];
            } else if (routerIsActive) {
                activeSubmenus[name] = true;
                return true;
            }
            return false;
        }

        return {
            can,
            toggleSubmenu,
            isSubmenuActive,
            userIs,
        }
    },
};
</script>
