import { createRouter, createWebHistory } from 'vue-router';

const Login = () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
// const Logout = () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
const Employee = () => import(/* webpackChunkName: "employees" */ '../views/Employee.vue')

const Booking = () => import(/* webpackChunkName: "booking" */ '../views/Booking.vue')
const Complaint = () => import(/* webpackChunkName: "complaint" */ '../views/Complaint.vue')
const Itinerary = () => import(/* webpackChunkName: "itinerary" */ '../views/Itinerary.vue')
const PrintItinerary = () => import(/* webpackChunkName: "printitinerary" */ '../components/PrintItinerary.vue')
const PrintHotelVoucher = () => import(/* webpackChunkName: "printhotelvoucher" */ '../components/PrintHotelVoucher.vue')
const PrintRestaurantVoucher = () => import(/* webpackChunkName: "printrestaurantvoucher" */ '../components/PrintRestaurantVoucher.vue')

const Hotel = () => import('../views/Hotel.vue')
const Restaurant = () => import('../views/Restaurant.vue')
const TourGuide = () => import(/* webpackChunkName: "tourguide" */ '../views/TourGuide.vue')
const Transportation = () => import(/* webpackChunkName: "transportation" */ '../views/Transportation.vue')
const Driver = () => import(/* webpackChunkName: "driver" */ '../views/Driver.vue')
const Boat = () => import(/* webpackChunkName: "boat" */ '../views/Boat.vue')

const FormView = () => import('../views/FormView.vue')
const UserAccount = () => import(/* webpackChunkName: "useraccount" */ '../views/UserAccount.vue')
const RoleManagement = () => import(/* webpackChunkName: "rolemanagement" */ '../views/RoleManagement.vue')
const About = () => import(/* webpackChunkName: "about" */ '../views/About.vue')
const NotFound = () => import(/* webpackChunkName: "notfound" */ '../views/404.vue')

const routes = [
    {
        path: "/",
        name: "login",
        component: Login,
        meta: { title: "Tour Operator System", }
    },
    {
        path: "/logout",
        name: "logout",
        // component: Logout,
        meta: { title: "Tour Operator System", }
    },
    {
        path: "/home",
        name: "home",
        component: Booking,
        meta: { title: "Tour Operator System", }
    },
    {
        path: "/booking",
        name: "booking",
        component: Booking,
        meta: { title: "Booking - Tour Operator System", }
    },
    {
        path: "/booking/new-booking",
        name: "newbooking",
        component: FormView,
        meta: { title: "New Booking - Tour Operator System", },
    },
    {
        path: "/booking/update-booking/:id",
        name: "updatebooking",
        component: FormView,
        meta: { title: "Update Booking - Tour Operator System", }
    },
    {
        path: "/booking/print-hotelvoucher",
        name: "printhotelvoucher",
        component: PrintHotelVoucher,
        meta: { title: "Print Hotel Voucher - Tour Operator System", }
    },
    {
        path: "/booking/print-restaurantvoucher",
        name: "printrestaurantvoucher",
        component: PrintRestaurantVoucher,
        meta: { title: "Print Restaurant Voucher - Tour Operator System", }
    },
    {
        path: "/complaint",
        name: "complaint",
        component: Complaint,
        meta: { title: "Booking Complaint - Tour Operator System", }
    },
    {
        path: "/itinerary",
        name: "itinerary",
        component: Itinerary,
        meta: { title: "Itinerary - Tour Operator System", }
    },
    {
        path: "/itinerary/add-itinerary",
        name: "additinerary",
        component: FormView,
        meta: { title: "Add Itinerary - Tour Operator System", }
    },
    {
        path: "/itinerary/update-itinerary/:id",
        name: "updateitinerary",
        component: FormView,
        meta: { title: "Update Itinerary - Tour Operator System", }
    },
    {
        path: "/itinerary/print-itinerary",
        name: "printitinerary",
        component: PrintItinerary,
        meta: { title: "Print Itinerary - Tour Operator System", }
    },
    {
        path: "/hotel",
        name: "hotel",
        component: Hotel,
        meta: { title: "Hotel - Tour Operator System", }
    },
    {
        path: "/hotel/update-contract",
        name: "updatehotelcontract",
        component: FormView,
        meta: { title: "Update Hotel Contract - Tour Operator System", }
    },
    {
        path: "/restaurant",
        name: "restaurant",
        component: Restaurant,
        meta: { title: "Restaurant - Tour Operator System", }
    },
    {
        path: "/restaurant/update-contract",
        name: "updaterestaurantcontract",
        component: FormView,
        meta: { title: "Update Restaurant Contract - Tour Operator System", }
    },
    {
        path: "/tourguide",
        name: "tourguide",
        component: TourGuide,
        meta: { title: "Tour Guide - Tour Operator System", }
    },
    {
        path: "/tourguide/add-tour-guide",
        name: "addtourguide",
        component: FormView,
        meta: { title: "Add Tour Guide - Tour Operator System", }
    },
    {
        path: "/tourguide/update-tour-guide/:id",
        name: "updatetourguide",
        component: FormView,
        meta: { title: "Update Tour Guide - Tour Operator System", }
    },
    {
        path: "/employee",
        name: "employee",
        component: Employee,
        meta: { title: "Employee - Tour Operator System", },
        // children: [
        //     {
        //         path: '/employee/emergency-contact',
        //         component: () => import('../components/Employee_EmergencyContact.vue')
        //     },
        // ]
    },
    {
        path: "/employee/add-employee",
        name: "addemployee",
        component: FormView,
        meta: { title: "Add Employee - Tour Operator System", }
    },
    {
        path: "/employee/update-employee/:id",
        name: "updateemployee",
        component: FormView,
        meta: { title: "Update Employee - Tour Operator System", }
    },
    {
        path: "/update-profile/:id",
        name: "updateprofile",
        component: FormView,
        meta: { title: "Update User Profile - Tour Operator System", }
    },
    {
        path: "/transportation",
        name: "transportation",
        component: Transportation,
        meta: { title: "Transportation Company - Tour Operator System", }
    },
    {
        path: "/transportation/update-contract",
        name: "updatetransportationcontract",
        component: FormView,
        meta: { title: "Update Transportation Contract - Tour Operator System", }
    },
    {
        path: "/driver",
        name: "driver",
        component: Driver,
        meta: { title: "Driver - Tour Operator System", }
    },
    {
        path: "/boat",
        name: "boat",
        component: Boat,
        meta: { title: "Boat - Tour Operator System", }
    },
    {
        path: "/useraccount",
        name: "useraccount",
        component: UserAccount,
        meta: { title: "User Account - Tour Operator System", }
    },
    {
        path: "/rolemanagement",
        name: "rolemanagement",
        component: RoleManagement,
        meta: { title: "Role Management - Tour Operator System", }
    },
    {
        path: "/about",
        name: "about",
        component: About,
        meta: { title: "About - Tour Operator System", }
    },
    {
        path: "/redirect/",
        name: "redirect",
        beforeEnter: (to) => {
            // console.log(to.query.url)
            window.location.href = 'http://'+to.query.url
            return false
        },
    },
    //404 Page
    {
        path: '/:catchAll(.*)',
        name: "NotFound",
        component: NotFound
    }
  ]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// import { store } from '../store'
import { checkSessionLocalStorage } from '../middleware/checkSession';

router.beforeEach((to, from, next) => {
    const isLoggedIn = checkSessionLocalStorage()
    if (to.name != 'login' && to.name != 'logout' && isLoggedIn == null) {
        // store.dispatch('sessionExpire', true)
        next({path: '/'}) //Go to login
    } else {
        next()
    }
})

// router.afterEach((to) =>{
//     if(to.name == 'login')  {
//         store.dispatch('sessionExpire', false)
//         // router.push({path: '/'})
//     }
// })

export default router
