export const boatInfo = {
    state: { //Contain data we want to use in our store, like data property of Vue Component
        boatUpdateForm: {}, //Use to store driver data in update form
    }, 
    getters: { //Get data from our state

    }, 
    mutations: { //Function to change value of state (commit mutation), we can't trigger asyc code inside mutation.
        BOAT_UPDATEFORM(state, payload) {
            state.boatUpdateForm = payload
        },
    }, 
    actions: { //Function to commit mutations, we can have async code in actions
        boat_updateform({ commit }, value) {
            commit('BOAT_UPDATEFORM', value)
        },
    },
};