import axios from '../axios.instance'

export default class LoginService {
    async authUser(user){
        try {
            const res = await axios.post('/login', user)
            return res.data
        } catch (error) {
            return {error: error.response.data}
        }
    }

    async logoutUser(){
        try {
            const res = await axios.delete('/logout')
            return res.data
        } catch (error) {
            console.log(error.response.data)
            // return {error: error.response.data}
        }
    }
}