<template>
    <form @submit.prevent="uploadImage" enctype='multipart/form-data'>
        <div class="upload-box">
            <div :style="{'margin':'10px 0px 8px 0px', 'cursor':previewSelectedImage==''?'arrow':'pointer'}" @click="editCroppedImage">
                <img style="border-radius: 3%; max-height: 170px" :src="previewSelectedImage || previewImgUrl || require('@/../public/images/image-placeholder.jpg')" />
            </div>
   
            <!-- <div class="overlay" v-show="loading">
                <ProgressSpinner style="width:50px; height:50px" strokeWidth="4" fill="#EEEEEE" animationDuration=".9s"/>
            </div> -->
          
            <div class="box box-stretched">
                <div class="upload-label">
                    <label class="p-button p-button-sm p-button-primary" style="width: 85px">
                        <i class="pi pi-upload" style="margin-right: 8px"></i>
                        Photo
                        <input
                            type="file"
                            ref="fileSelection"
                            @change="loadImage($event)"
                            style="display: none"
                        />
                    </label>
                </div>
            </div>
        </div>

        <Dialog v-model:visible="showCropDialog" :style="{width: '32rem'}" :modal="true">
            <template #header>
                <h5 style="margin: 0px">Crop Photo</h5>
            </template>

            <ProgressSpinner v-if="loadingPhoto" style="width:50px; height:50px" strokeWidth="4" fill="#EEEEEE" animationDuration=".9s"/>

            <cropper
                ref="cropper"
                :src="image.src"
                default-boundaries="fill"
                :stencil-props="{aspectRatio: 4/4}"
            />

            <div class="p-fluid crop-button-bar">
                <Button class="p-button-secondary p-mr-2" label="Close" @click="closeCroppedDialog"/>
                <Button label="OK" @click="crop"/>
            </div>
        </Dialog>

        <Dialog header="Image Preview" v-model:visible="previewImage" :style="{width: '38rem'}" :modal="true">
            <div class="preview-image">
                <img :src="previewImgUrl" />
            </div>
        </Dialog>
    </form>    
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import axios from '../axios.instance';
import ProgressSpinner from 'primevue/progressspinner';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    props:{
        previewImgUrl: String
    },
    setup(props){
        const store = useStore();

        // let selectedFile = ref(null)
        let previewSelectedImage = ref('')
        let errorMsg = ref('')
        let fileExtension = ref('')
        let loading = ref(false)
        let fileSelection = ref() //$refs for file input

        let loadingPhoto = ref(false)
        let cropper = ref()
        const showCropDialog = ref(false)
        let image = reactive({
				src: null,
				type: null
			})
        let photoBlob = ref(null)
        let previewImage = ref(false)

        //Methods
        const editCroppedImage = () => {
            if(previewSelectedImage.value != '') {
                showCropDialog.value = true
            } else {
                if(props.previewImgUrl) {
                    previewImage.value = true
                }
            }

        }

        const closeCroppedDialog = () => {
            showCropDialog.value = false
            if (image.src == null) {
                destroyed()
            }
        }

        const destroyed = () => {
            // selectedFile.value = null
            if (image.src) {
               URL.revokeObjectURL(image.src)
            }
        }

        function crop () {
			const { canvas } = cropper.value.getResult();
            if(canvas) {
                canvas.toBlob(blob => {
                    photoBlob.value = blob
                }, image.type)
            }
            previewSelectedImage.value = canvas.toDataURL();
            showCropDialog.value = false
		}

        const loadImage = (event) => {
			const { files } = event.target;
			if (files && files[0]) {
                const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

                if(allowedTypes.includes(files[0].type)) {
                    showCropDialog.value = true
                    loadingPhoto.value = true

                    if (image.src) {
                        URL.revokeObjectURL(image.src)
                    }
                    const blob = URL.createObjectURL(files[0]);
                    loadingPhoto.value = false
                    
                    if(files[0].type == 'image/jpeg') {
                        fileExtension.value = '.jpeg';
                    } else if (files[0].type == 'image/jpg') {
                        fileExtension.value = '.jpg';
                    } else if (files[0].type == 'image/png') {
                        fileExtension.value = '.png';
                    }
                    store.dispatch('setPhotoExt', fileExtension.value); //Set image extension info in store
                    store.dispatch('hasPhoto', true); //Set image info in store
                    
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        image.src = blob
                        image.type = getMimeType(e.target.result, files[0].type)
                    };
                    reader.readAsArrayBuffer(files[0]);
                } else {
                    errorMsg.value = "Only JPEG, JPG & PNG file are allowed.";
                    alert(errorMsg.value)
                    loading.value = false;
                } 
			}
		}

        function getMimeType(file, fallback = null) {
            const byteArray = (new Uint8Array(file)).subarray(0, 4);
            let header = '';
            for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
            }
            switch (header) {
                case "89504e47":
                    return "image/png";
                case "47494638":
                    return "image/gif";
                case "ffd8ffe0":
                case "ffd8ffe1":
                case "ffd8ffe2":
                case "ffd8ffe3":
                case "ffd8ffe8":
                    return "image/jpeg";
                default:
                    return fallback;
            }
        }

        async function uploadImage () {
            const fileName = store.state.uploadImage.photoInfo.photoName + fileExtension.value;

            const formData = new FormData();
            formData.append('file', photoBlob.value);

            try {
                await axios.post('/upload-image', formData, { params: { photoname: fileName } });
                
                // selectedFile.value = "";
                previewSelectedImage.value = "";
                fileExtension.value = "";
                store.dispatch('hasPhoto', false);
                // console.log('image upate')
            } catch (error) {
                alert('Something went wrong uploading image. Error: ' + error.response.data.error)
            }
		}

        return {
            // selectedFile,
            previewSelectedImage,
            errorMsg,
            fileExtension,
            loading,
            fileSelection,

            // selectFile,
            previewImage,
            uploadImage,
            loadImage,

            loadingPhoto,
            showCropDialog,
            cropper,
            crop,
            image,
            editCroppedImage,
            closeCroppedDialog,
        }
    },
    components: {
        ProgressSpinner,
        Cropper,
        // CircleStencil
    }     
}    
</script>

<style lang="scss" scoped>
    .upload-label {
        text-align: center;

        label {
            padding-left: 10px
        }
    }
    
    .upload-box {
        text-align: center;
    }

    .overlay {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.7;
        position: absolute;
        z-index: 9;
        padding-top: 30%;
        background: #c3c3c3;
      }

    .crop-button-bar {
        display: flex;
        margin-top: 1rem;
    }

    .preview-image {
        img {
            // width: 50%;
            // display: block;
            width:100%;
            height:100%;
            object-fit:cover;
        }
    }
</style>