<template>
<div class="change-pwd-form">
    <form @submit.prevent="updatePassword()">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="old_password" class="p-col-fixed">Old Password:</label>
                <div class="p-col">
                    <Password id="old_password" v-model="userPwdForm.old_pwd" autocomplete="on" :feedback="false" :class="{'p-invalid':v$.old_pwd.$error}" toggleMask/>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="new_password" class="p-col-fixed">New Password:</label>
                <div class="p-col">
                    <Password id="new_password" v-model="userPwdForm.new_pwd" autocomplete="on" :feedback="false" :class="{'p-invalid':v$.new_pwd.$error || submitted && v$.conf_pwd.sameAs.$invalid}" toggleMask/>
                </div>
            </div>
            <div class="p-field p-grid" style="margin-bottom: 0">
                <label for="conf_password" class="p-col-fixed">Confirm Password:</label>
                <div class="p-col">
                    <Password id="conf_password" v-model="userPwdForm.conf_pwd" autocomplete="on" :feedback="false" :class="{'p-invalid':v$.conf_pwd.$error}" toggleMask/>
                </div>
            </div>
            <div class="p-field p-grid">
                <div class="p-col" style="margin-left: 130px">
                    <small v-if="submitted && v$.conf_pwd.sameAs.$invalid" class="p-error">New password must be matched.</small>
                </div>
            </div>

            <Button label="Save" type="submit" class="p-button p-button-warning"></Button>
        </div>
    </form>
</div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, sameAs } from '@vuelidate/validators';
import Password from 'primevue/password';
import UserAccountService from '../service/UserAccountService';

export default {
    setup() {
        const store = useStore()
        let userPwdForm = reactive({})
        let submitted = ref(false)

        const userAccountService = ref(new UserAccountService());

        onMounted(() => {
            initPasswordForm()
        })

        const initPasswordForm = () =>{
            const initForm = {
                old_pwd: '',
                new_pwd: '',
                conf_pwd: ''
            }

            Object.assign(userPwdForm, initForm)
        }

        const updatePassword = () => {
            submitted.value = true

            if(validateForm()) {
                userAccountService.value.changePassword(userPwdForm).then(data => {
                    if(data.errorResponse) {
                        showMessage('warn', data.errorResponse, 4000)
                    } else {
                        if(data.status == 404) {
                            return showMessage('warn', data.message, 4000)
                        }

                        showMessage('success', 'Password has been changed successfully.', 2500)
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const showMessage = (severity, msgContent, life) => {
            let message = {
                isMsgShow: true, 
                severity: severity, 
                content: msgContent
            }

            store.dispatch('showMsg', message)
            if(life) {
                setTimeout(() => {
                    store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
                }, life);
            }
        }

        //==============FORM VALIDATION==============
        const rules = computed(() => {
            return {
                old_pwd: { required },
                new_pwd: { required },
                conf_pwd: { required, sameAs: sameAs(userPwdForm.new_pwd) },
            }            
        })
        const v$ = useVuelidate(rules, userPwdForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            v$,
            userPwdForm,
            updatePassword,
            submitted,
        }
    },
    components: {
        Password
    }
}
</script>

<style lang="scss" scoped>
.change-pwd-form {
    margin: 1rem .5rem;
}
.change-pwd-form {
    label {
        width: 130px;
        margin: 0;
    }
}
</style>