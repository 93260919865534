import { createStore } from "vuex";
import { uploadImage } from "./modules/uploadImage";
import { employeeInfo } from "./modules/employeeInfo";
import { hotelInfo } from "./modules/hotelInfo";
import { hotelContract } from "./modules/hotelContract";
import { restaurantInfo } from "./modules/restaurantInfo";
import { transportationInfo } from "./modules/transportationInfo";
import { driverInfo } from "./modules/driverInfo";
import { boatInfo } from "./modules/boatInfo";
import { userAccount } from "./modules/userAccount";
// import { modulePermission } from "./modules/modulePermission_NoNeed";

export const store = createStore({
    state: { //Contain data we want to use in our store, like data property of Vue Component
        appSideBar: true,
        appTopBar: true,
        clearToken: false,
        sessionExpire: false,
        loadingScreen: false,
        networkError: false,
        requestTimeout: false,
        progressBar: false,

        showMsg: {
            isMsgShow: false,
            severity: '',
            content: ''
        },
    }, 
    getters: { //Get data from our state
        
    }, 
    mutations: { //Function to change value of state (commit mutation), we can't trigger asyc code inside mutation.
        SHOW_APP_SIDEBAR(state, value){
            state.appSideBar = value;
        },
        SHOW_APP_TOPBAR(state, value){
            state.appTopBar = value;
        },
        SHOW_MSG(state, payload) {
            state.showMsg = payload
        },
        CLEAR_TOKEN(state, payload) {
            state.clearToken = payload
        },
        SESSION_EXPIRE(state, payload) {
            state.sessionExpire = payload
        },
        REQUEST_TIMEOUT(state, payload) {
            state.requestTimeout = payload
        },
        LOADING_SCREEN(state, payload) {
            state.loadingScreen = payload
        },
        NETWORK_ERROR_SCREEN(state, payload) {
            state.networkError = payload
        },
        SHOW_PROGRESSBAR(state, payload) {
            state.progressBar = payload
        },
    }, 
    actions: { //Function to commit mutations, we can have async code in actions
        showSideBar({ commit }, value){
            commit('SHOW_APP_SIDEBAR', value)
        },
        showTopBar({ commit }, value){
            commit('SHOW_APP_TOPBAR', value)
        },
        showMsg({ commit }, value) {
            commit('SHOW_MSG', value)
        },
        clearToken({ commit }, value) {
            commit('CLEAR_TOKEN', value)
        },
        sessionExpire({ commit }, value) {
            commit('SESSION_EXPIRE',  value)
        },
        requestTimeout({ commit }, value) {
            commit('REQUEST_TIMEOUT',  value)
        },
        showLoadingScreen({ commit }, value) {
            commit('LOADING_SCREEN', value)
        },
        showNetworkErrorScreen({ commit }, value) {
            commit('NETWORK_ERROR_SCREEN', value)
        },
        showProgressBar({ commit }, value) {
            commit('SHOW_PROGRESSBAR', value)
        },
    },
    modules: { //Allow us to break our store in each module.
        uploadImage,
        employeeInfo,
        hotelInfo,
        hotelContract,
        restaurantInfo,
        transportationInfo,
        driverInfo,
        boatInfo,
        userAccount,
        // modulePermission,
    }
});