export const employeeInfo = {
    state: { //Contain data we want to use in our store, like data property of Vue Component
        EmpID: 0,
        isContactList: true, //Use to check if contact list table is showed
    }, 
    getters: { //Get data from our state

    }, 
    mutations: { //Function to change value of state (commit mutation), we can't trigger asyc code inside mutation.
        SET_F_EMPID(state, value){
            state.EmpID = value
        },
        SET_ISCONTACTLIST(state, value){
            state.isContactList = value
        },
    }, 
    actions: { //Function to commit mutations, we can have async code in actions
        setEmpID({ commit }, empID) {
            commit('SET_F_EMPID', empID)
        },
        setIsContactList({ commit }, value) {
            commit('SET_ISCONTACTLIST', value)
        },
    },
};