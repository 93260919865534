export const restaurantInfo = {
    state: { //Contain data we want to use in our store, like data property of Vue Component
        restaurantUpdateForm: {}, //Use to store restaurant data in update form

        restaurantID: 0,
        isRestaurantContactList: true, //Use to check if restaurant contact person list is showed
    }, 
    getters: { //Get data from our state

    }, 
    mutations: { //Function to change value of state (commit mutation), we can't trigger asyc code inside mutation.
        RESTAURANT_UPDATEFORM(state, payload) {
            state.restaurantUpdateForm = payload
        },
        SET_ISRESTAURANTCONTACTLIST(state, value){
            state.isRestaurantContactList = value
        },
        SET_F_RESTAURANTID(state, value){
            state.restaurantID = value
        },
    }, 
    actions: { //Function to commit mutations, we can have async code in actions
        restaurant_updateform({ commit }, value) {
            commit('RESTAURANT_UPDATEFORM', value)
        },
        setIsRestaurantContactList({ commit }, value) {
            commit('SET_ISRESTAURANTCONTACTLIST', value)
        },
        setContactPerson_RestID({ commit }, RestaurantID) {
            commit('SET_F_RESTAURANTID', RestaurantID)
        },
    },
};