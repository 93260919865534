export const hotelInfo = {
    state: { //Contain data we want to use in our store, like data property of Vue Component
        hotelUpdateForm: {}, //Use to store hotel data in update form

        hotelID: 0,
        isHotelContactList: true, //Use to check if hotel contact person list is showed
    }, 
    getters: { //Get data from our state

    }, 
    mutations: { //Function to change value of state (commit mutation), we can't trigger asyc code inside mutation.
        HOTEL_UPDATEFORM(state, payload) {
            state.hotelUpdateForm = payload
        },
        SET_F_HOTELID(state, value){
            state.hotelID = value
        },
        SET_ISHOTELCONTACTLIST(state, value){
            state.isHotelContactList = value
        },
    }, 
    actions: { //Function to commit mutations, we can have async code in actions
        hotel_updateform({ commit }, value) {
            commit('HOTEL_UPDATEFORM', value)
        },
        setIsHotelContactList({ commit }, value) {
            commit('SET_ISHOTELCONTACTLIST', value)
        },
        setContactPerson_HotelID({ commit }, hotelID) {
            commit('SET_F_HOTELID', hotelID)
        },
    },
};