<template>
    <div class="login-page">
        <div class="card">
            <div class="login-logo">
                <!-- <img alt="logo" src="@/assets/images/primevue-logo.png" /> -->
                <!-- <h3 class="p-text-center">Welcome</h3> -->
                <img alt="logo" src="../assets/images/adt-logo.png" />
            </div>

            <div class="login-form">
                <transition name="show">  
                    <div v-if="showProgress" class="loading-progress">
                        <ProgressSpinner style="width:45px;height:45px" class="p-ml-1 p-mr-3" strokeWidth="3" fill="#EEEEEE" animationDuration="1.1s"/>
                        <span style="font-weight: 500; font-size: 16px">Logging in...</span>
                    </div>
                </transition>
                
                <transition name="show">
                    <form v-if="!showProgress" @submit.prevent="loginUser()" class="p-fluid" >
                        <div class="p-field">
                            <div class="p-input-icon-right">
                                <i class="pi pi-user" />
                                <InputText id="username" v-model="loginForm.username" placeholder="Username" :class="{'p-invalid':v$.username.$error}" autofocus/>
                            </div>
                        </div>
                        <div class="p-field">
                            <div class="p-input-icon-right">
                                <i class="pi pi-envelope" />
                                <Password v-model="loginForm.password" inputStyle="padding: .5rem" id="password" autocomplete="on" :feedback="false" :class="{'p-invalid':v$.password.$error}" placeholder="Password" toggleMask/>
                            </div>
                        </div>
                        <div class="p-field">
                            <Button label="Login" type="info"></Button>
                            <InlineMessage class="p-mt-2" v-if="showMsg.isMsgShow" :severity="showMsg.severity">{{showMsg.content}}</InlineMessage>
                        </div>
                    </form>
                </transition>
            </div>
        </div>
    </div>    
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Password from 'primevue/password';
import ProgressSpinner from 'primevue/progressspinner';
import AuthService from '../service/AuthService';
import { checkSessionLocalStorage } from '../middleware/checkSession';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { unpackRules } from '@casl/ability/extra';
import { defineAbility } from '../service/ability';

export default({
    setup() {
        const store = useStore()
        const router = useRouter()

        const showMsg = computed(() => store.state.showMsg)

        const loginForm = reactive({
            username: '',
            password: ''
        })
        const showProgress = ref(false)
        const loginError = ref('')

        //On Create        
        const authService = ref(new AuthService())

        onMounted(() => {
            if(checkSessionLocalStorage() != null) {
                router.push({ path: '/booking' })
            } else {
                // authService.value.logoutUser().then(res => {
                //     if(res.message == 'success'){
                //         router.push({ path: '/' })
                //     }
                // })
            }
            // if(store.state.clearToken == true) {
            //     authService.value.logoutUser().then(res => {
            //         if(res.message == 'success'){
            //             router.push({ path: '/' })
            //         }
            //     })
            // }
        })

        //Methods
        const loginUser = () => {
            if(validateLogin()) {
                showProgress.value = true
                authService.value.authUser(loginForm).then(res =>{
                    if(!res.error){
                        store.dispatch('showLoadingScreen', true)
                        
                        defineAbility(unpackRules(res.rules))
                        setLocalStorage(res.empid, res.expireIn) //Expire in hour
                        // setLocalStorage('userIsLoggedIn', 4000)
                        
                        showProgress.value = false
                        store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
                        router.push({ path: '/booking' })
                    } else {
                        showProgress.value = false
                        // loginError.value = 'Username or Password is invalid.'
                        // console.log(res.error)
                        showMessage('error', 'Username or Password is invalid.')
                        
                        if(res.error.status == 400)
                            showMessage('warn', res.error.message)
                        
                        if(res.error.status == 500)
                            showMessage('error', 'Internal Server Error')
                    }
                })
            }
        }

        const setLocalStorage = (empid, ttl) => {
            const now = new Date()
            const item = {
                value: {id: empid },
                expiry: now.getTime() + ttl,
            }
            localStorage.setItem('isAuthenticate', JSON.stringify(item))
        }

        //Form Validations
        const rules = computed(() =>  {
            return {
                username: { required },
                password: { required },
            }            
        })
        const v$ = useVuelidate(rules, loginForm)
        const validateLogin = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        const showMessage = (severity, msgContent) => {
            let message = {
                isMsgShow: true, 
                severity: severity, 
                content: msgContent
            }

            store.dispatch('showMsg', message)
        }

        // const checkLocalStorage = () => {
        //     const itemStr = localStorage.getItem('isAuthenticate')

        //     // if the item doesn't exist, return null
        //     if (!itemStr) {
        //         return null
        //     }

        //     const item = JSON.parse(itemStr)
        //     const now = new Date()

        //     // compare the expiry time of the item with the current time
        //     if (now.getTime() > item.expiry) {
        //         localStorage.removeItem('isAuthenticate')
        //         store.dispatch('sessionExpire', true)
        //         return null
        //     }
        //     return item.value
        // }

        return {
            v$,
            showProgress,
            loginForm,
            loginUser,
            loginError,
            showMsg,
        }
    },

    components:{
        Password,
        ProgressSpinner
    }
})
</script>

<style lang="scss" scoped>
.login-page {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.login-form {
    width: 23rem;
}
.p-inputtext {
    padding: .5rem;
}
.login-logo {
    padding-bottom: 1.3rem;
    text-align: center;

    img {
        image-rendering: -moz-crisp-edges;         /* Firefox */
        image-rendering:  -o-crisp-edges;         /* Opera */
        image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
    }
}
.card {
    background: var(--surface-e);
    padding: 3rem;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
    margin-top: .7rem;
    margin-bottom: .7rem;
    height: 22.5rem;
}
.loading-progress  {
    height: 147px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.show-enter-active{
    transition: all .3s ease;
}
.show-leave-active {
    opacity: 0;
} 
.show-leave-to,
.show-enter-from {
    opacity: 0;
    transform: translateY(30px); 
}

.p-inline-message .p-inline-message-icon {
    display: none;
}
</style>