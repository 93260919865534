import axios from 'axios';
import API_CONFIG from './config'
import { store } from './store';

const instance = axios.create({
        baseURL: API_CONFIG.BASE_URL,
        // baseURL: 'http://localhost:3000/api',
        // baseURL: 'http://192.168.88.5:3000/api',
        // baseURL: 'http://system.angkordestination.com:3000/api',
        timeout: 8000,
        withCredentials: true,
        // Accept: 'application/json',
        // headers: {'X-Custom-Header': 'foobar'}
});
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

let progress = [];
instance.interceptors.request.use((config) => {
        // Do something before request is sent
        progress.push(setTimeout(() => {
                store.dispatch('showProgressBar', true)
        }, 250));
        return config;
}, function (error) {
        // Do something with request error
        
        return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
        // store.dispatch('showLoadingScreen', false)
        store.dispatch('showProgressBar', false)
        clearProgress()

        return response;
}, (error) => {
        if (error == 'Error: timeout of 8000ms exceeded') {
                store.dispatch('requestTimeout', true)
                store.dispatch('showProgressBar', false)
                clearProgress()
        }
        if (error == 'Error: Network Error') {
                store.dispatch('showLoadingScreen', true)
                store.dispatch('showNetworkErrorScreen', true)
        }
        if (error.response.status == 401 || error.response.data.errorResponse == 'No access token provided.') {
                //Do Something
                store.dispatch('sessionExpire', true)
        }
        store.dispatch('showProgressBar', false)
        clearProgress()

        return Promise.reject(error);
});

function clearProgress() {
        for(var i = 0; i < progress.length; i++){ 
                clearTimeout(progress[i])
                progress.splice(i, 1); 
        }
}

export default instance;