import { store } from '../store'

export const checkSessionLocalStorage = () => {
    const itemStr = localStorage.getItem('isAuthenticate')

    //IF ITEM DOESN'T EXIST, RETURN Null
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    //COMPARE EXPIRY TIME OF THE ITEM WITH CURRENT TIME
    if (now.getTime() > item.expiry) {
        store.dispatch('sessionExpire', true)
        localStorage.removeItem('isAuthenticate')
        return null
    }
    return item.value
}

// export default checkSessionLocalStorage;