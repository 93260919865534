export const userAccount = {
    state: { //Contain data we want to use in our store, like data property of Vue Component
        user_account: {}, //Use in add/update user account form
        linked_userprofile: [], //To filter profile that already linked 
        user_roleid: 0,
    }, 
    getters: { //Get data from our state

    }, 
    mutations: { //Function to change value of state (commit mutation), we can't trigger asyc code inside mutation.
        USER_ACCOUNT(state, payload) {
            state.user_account = payload
        },
        LINKED_USERPROFLILE(state, payload) {
            state.linked_userprofile = payload
        },
        USER_ROLEID(state, payload) {
            state.user_roleid = payload
        },
    }, 
    actions: { //Function to commit mutations, we can have async code in actions
        useraccount_info({ commit }, value) { //Use in add/update user account form
            commit('USER_ACCOUNT', value)
        },
        linked_userprofile({ commit }, value) {
            commit('LINKED_USERPROFLILE', value)
        },
        user_roleID({ commit }, value) { //Get role_id when selected user account
            commit('USER_ROLEID', value)
        },
    },
};