<template>
    <form @submit.prevent="form_type == 'add' ? addEmployee() : updateEmployee()">
        <div class="form-view employee-form"> <!-- Employee Form -->
            <Divider v-if="!UpdateProfile" align="left" style="margin-top: 0">
                <div class="p-d-inline-flex p-ai-center" style="background-color: none">
                    <span style="font-weight: 600">Employee information</span>
                </div>
            </Divider>
            <div class="p-grid p-fluid reserve-emp-form">
                <div class="p-col-12 p-md-9">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <label for="empid">Employee ID: </label>
                            <InputText id="empid" type="text" v-model="customEmpID" placeholder="ID is generated automatically." disabled />
                            <InputText type="hidden" v-model="form.id" />
                        </div>
                        <div class="p-col-6">
                            <label for="first_name">First name*:</label>
                            <InputText id="first_name" v-model="form.first_name" type="text" :class="{'p-invalid':v$.first_name.$error}" />
                            <small v-if="v$.first_name.$error" class="p-error">{{v$.first_name.required.$message.replace('Value', 'First name')}}</small>
                        </div>
                        <div class="p-col-6">
                            <label for="last_name">Last name:</label>
                            <InputText id="last_name" v-model="form.last_name" type="text" />
                        </div>
                        <div class="p-col-6">
                            <label for="sex">Gender*:</label>
                            <Dropdown v-model="selectedGender" @change="onGenderChange()" :options="gender" optionLabel="sex" :class="{'p-invalid':v$.sex.$error}" />
                            <small v-if="v$.sex.$error" class="p-error">{{v$.sex.required.$message.replace('Value', 'Sex')}}</small>
                        </div>
                        <div class="p-col-6">
                            <label for="nationality">Nationality:</label>
                            <InputText id="nationality" v-model="form.nationality" type="text" />
                        </div>
                        <div class="p-col-6">
                            <label for="dob">Date of Birth:</label>
                            <Calendar id="dob" v-model="form.dob" :manualInput="false" dateFormat="dd-M-yy" />
                        </div>
                        <div class="p-col-6">
                            <label for="pob">Place of Birth:</label>
                            <InputText id="pob" v-model="form.pob" type="text" />
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                     <div class="preview-photo">
                        <UploadImage ref="uploadimageComponent" :previewImgUrl="setPreviewPhoto" />
                    </div>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div v-if="UpdateProfile == false" class="p-col-6">
                    <label for="job_title">Job Title:</label>
                    <InputText id="job_title" v-model="form.job_title" type="text" />
                </div>
                <div v-if="UpdateProfile == false" class="p-col-6">
                    <label for="join_date">Join Date:</label>
                    <Calendar id="navigators" v-model="form.join_date" :manualInput="false" dateFormat="dd-M-yy" />
                </div>
                <div class="p-col-6">
                    <label for="phone">Phone*:</label>
                    <InputMask mask="999 999-999?9" v-model="form.phone" :class="{'p-invalid':v$.phone.$error}" />
                    <small v-if="v$.phone.$error" class="p-error">{{v$.phone.required.$message.replace('Value', 'Phone number')}}</small>
                </div>
                <div class="p-col-6">
                    <label for="email">Email:</label>
                    <InputText id="email" v-model="form.email" type="text" :class="{'p-invalid':v$.email.$error}" />
                    <small v-if="v$.email.$error" class="p-error">{{v$.email.$errors[0].$message}}</small>
                </div>
                <div class="p-col-12">
                    <label for="address">Address:</label>
                    <InputText id="address" v-model="form.address" type="text" />
                </div>
            </div>

            <hr style="margin: 15px 0px; border: 0; border-top: 1px solid #cbcdcf"/>
            <div class="p-mt-2">
                <div v-if="form_type == 'add'">
                    <Button v-if="can('create', 'employee')" label="Save" type="submit" class="p-button-success" icon="pi pi-save"></Button>
                </div>
                <div v-else>
                    <Button v-if="can('update', 'employee') || UpdateProfile" label="Update" type="submit" class="p-button-warning" icon="pi pi-save"></Button>
                </div>
            </div>
        </div> <!-- End Employee Form Card -->
    </form>

</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import UploadImage from '../components/UploadImage';
// import dayjs from 'dayjs';

import EmployeeService from '../service/EmployeeService';

export default {
    props: {
        UpdateProfile: Boolean,
        EmpID: Number
    },
    setup(props){
        const { can } = useAbility()
        const store = useStore()
        const route = useRoute()
        const uploadimageComponent = ref(null)
        const toast = useToast()

        let form = reactive({})        
        let form_type = ref('add')
        
        let selectedGender = ref()
        // const dob = ref('')
        // const join_date = ref('')
        const gender = [{sex: 'F'}, {sex: 'M'}]
        let setPreviewPhoto = ref()
        let emp_id = 0;
        let customEmpID = ref()

        //On Created
        const employeeService = ref(new EmployeeService())
        
        //On Mounted
        onMounted(() => { 
            initFormData();
            
            if(route.params.id) {
                emp_id = route.params.id
            } else {
                emp_id = props.EmpID
            }
            
            if (route.name == 'updateemployee' || props.UpdateProfile){         
                form_type.value = 'update'; //Set form type to show Update button
                employeeService.value.getEmployeeById(props.UpdateProfile, emp_id).then((data) => {
                    Object.assign(form, data)

                    if(form.dob) {
                        form.dob = new Date(form.dob)
                    } else {
                        form.dob = null
                    }
                    if(form.join_date) {
                        form.join_date = new Date(form.join_date)
                    } else {
                        form.join_date = null
                    }
                    
                    customEmpID.value = "EMP" + (emp_id+'').padStart(3,'0')
                    selectedGender.value = {sex: data.sex}
                    if(data.photo != ''){
                        setPreviewPhoto.value = require('@/../public/images/'+data.photo);
                    }
                })
            }
        })
        
        //Methods
        const addEmployee = () => {
            if(validateForm()){
                // if(form.dob != '') {
                //     // form.dob = new Date(dob.value).toLocaleDateString('en-CA');  //Format date to yyyy-mm-dd 
                //     form.dob = dayjs(form.dob).format('YYYY-MM-DD')
                // }
                // if(form.join_date != '') {
                //     // form.join_date = new Date(join_date.value).toLocaleDateString('en-CA');
                //     form.join_date = dayjs(form.join_date).format('YYYY-MM-DD')
                // }
                                            
                if(getPhotoName() == true){
                    uploadimageComponent.value.uploadImage();
                }

                employeeService.value.addEmployee(form).then((data) => {
                    if(data.errorResponse) {
                        toast.add({severity:'warn', summary: 'Add Data Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    } else {
                        initFormData();
                        toast.add({severity:'success', summary: 'Info', detail:'Data successfully added.', life: 3000});
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const updateEmployee = () => {
            if(validateForm()){
                // if(form.dob != '') {
                //     form.dob = dayjs(form.dob).format('DD-MMM-YYYY')
                // }
                // if(form.join_date != '') {
                //     form.join_date = dayjs(form.join_date).format('DD-MMM-YYYY')
                // }
                                
                if(getPhotoName() == true){
                    uploadimageComponent.value.uploadImage();
                }
                
                employeeService.value.updateEmployee(props.UpdateProfile, emp_id, form).then((data) => {
                    if(data.errorResponse) {
                        toast.add({severity:'warn', summary: 'Update Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    } else {
                        toast.add({severity:'success', summary: 'Update Info', detail:'Data successfully updated.', life: 3000});        
                    }
                })
            }
        }

        const getPhotoName = () => {
            if(store.state.uploadImage.hasPhoto == true) {
                const photoName = form.first_name.toLowerCase().replace(/\s/g, '') + form.last_name.toLowerCase().replace(/\s/g, '')
                store.dispatch('setPhotoName', photoName);

                form.photo = photoName + store.state.uploadImage.photoInfo.fileExt;
                
                return true;
            }
        }

        const initFormData = () => {
            const initForm = {
                first_name: '',
                last_name: '',
                sex: '',
                nationality: '',
                dob: '',
                pob: '',
                job_title: '',
                join_date: '',
                phone: '',
                email: '',
                address: '',
                photo: ''
            }

            selectedGender.value = ''
            Object.assign(form, initForm)
        }

        //Form Validations
        const rules = computed(() => {
            return {
                first_name: { required },
                sex: { required },
                phone: { required },
                email: { email }
            }            
        })
        const v$ = useVuelidate(rules, form)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        const onGenderChange = () => {
            form.sex = selectedGender.value.sex;
        }

        return {
            can,
            route,
            form_type,
            form,
            v$,
            // dob,
            // join_date,
            gender,
            selectedGender,
            uploadimageComponent,            
            setPreviewPhoto,
            customEmpID,

            addEmployee,
            updateEmployee,
            onGenderChange
        }
    },
    components: {
        UploadImage,
    }
}
</script>

<style lang="scss" scoped>
.employee-form {
    label {
        width: 120px;
    }
}
.p-col-6 {
    padding: .3rem .5rem;
}
</style>