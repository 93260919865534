<template>
    <div class="loading-screen">
        <!-- <img alt="logo" style="width: 13%" src="@/assets/images/primevue-logo.png" /> -->
        <img alt="logo" src="../assets/images/adt-logo.png" />
        
        <div v-if="!networkError" class="loading-progress">
          <ProgressSpinner style="width:35px;height:35px" class="p-ml-1 p-mr-3" strokeWidth="3" fill="#EEEEEE" animationDuration="1.1s"/> 
          <h5 style="margin: 0">System is loading...</h5>
        </div>

        <div v-if="networkError">
          <div class="loading-progress">
            <span style="font-size: 2rem; padding: 0 1rem; color:var(--orange-500)" class="pi pi-exclamation-triangle"></span>
            <h5 style="margin: 0;">Service is unavailable.</h5>
          </div>
            <h5 style="margin-top: 8px">Server is down or under maintenance.</h5>     
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import ProgressSpinner from 'primevue/progressspinner';
export default {
    setup() {
        const store = useStore();
        const networkError = computed(() => store.state.networkError)

        return {
          networkError,
        }
    },
    components: {
      ProgressSpinner
    }
}
</script>

<style scoped>
.loading-screen {
  top: 0;
  display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #f2f4f6;
	z-index: 9999;
}

.loading-progress {
  margin-top: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>